import '../styles/homepageStyle.css'
import { Link } from 'react-router-dom'
import front_counter from '../images/storepics/front_counter.jpg'
import athletic_display from '../images/storepics/athletic_display.jpg'
import panorama from '../images/storepics/panoramic.jpg'
import AnimateOnScroll from './animate'
import SignUpForm from './signup'
import AboutUs from './aboutUs'
import Carousel from './carousel/brandcarousel'
import MapMarker from './createMap'
import { useState } from 'react'

export default function Homepage () {

    const storeImages = [
        {name: 'Counter',
        image: front_counter},
        {name: 'Athletic',
        image: athletic_display},
        {name: 'Panorama',
        image: panorama},
    ]

    const [modalOpen, setModalOpen] = useState(false)
    const [activeImage, setActiveImage] = useState('')

    function openModal(image) {
        setActiveImage(image)
        setModalOpen(true)
    }

    function closeModal() {
        setModalOpen(false)
    }

    // <div className='seasonalBanner'>
    //             <div className='seasonalContent'>
    //                 <div className='seasonalTitle'>We will be closed February 19th for Presidents' Day 
    //                 </div> 
    //                 {/* <div className='seasonalText'>No matter what holiday you celebrate,<br></br></div> */}
    //             </div>
    //         </div>

    // <div className='seasonalBanner'>
    //              <div className='seasonalContent'>
    //                 <div className='seasonalTitle'>Spring Sale! 20% off your entire purchase!</div>
    //                 <div className='seasonalTitle'>Sale begins Friday April 12th through Saturday April 20th!</div> 
    //                  <div className='seasonalText'>Includes all in-stock shoes....even clearance!<br></br>Lots of new spring & summer shoes have arrived - shop soon for best selection.</div>
    //              </div>
    //          </div>

//     <div className='seasonalBanner'>
//     <div className='planeBanner'>
//         <img className='badge' src={Badge1} alt='cloud banner'></img>
//         <img className='plane' src={Plane} alt='Shop Small banner'></img>
//         <img className='badge' src={Badge2} alt='cloud banner'></img>
//     </div>
//     <div className='seasonalContent'>
//         <div className='seasonalTitle'>
//             Shop Small....Save Big! Special Two-Day Sale to celebrate Small Business Saturday!<br></br>
//             Thanks for supporting us! Buy local....or Bye-Bye local!
//         </div> 
//         <div className='seasonalText'>
//             Mark your calendar for the Friday and Saturday after Thanksgiving — 
//             and get ready to Shop Small with us. <br></br>We're celebrating with a two day 
//             special event on Friday and Saturday, November 29th and 30th. 
//             <br></br>All shoes will be 20% off on these two days only! So come shop small with us and save!
//             Sale on in-stock shoes only. Excludes Gift Certificates<br></br>Special Holiday Hours:          Open 10 - 3 Wednesday, November 27th;  Closed Thanksgiving Day, Thursday November 28th
//         </div>
//     </div>
// </div>

    return (
        <div className='homepage'>
            <div className='seasonalBanner'>
                <div className='seasonalContent'>
                    <div className='seasonalTitleOne'>New Temporary Store Hours</div>
                    <div className='seasonalTitle'>Otherwides will be open Wed - Sat from 10 - 5</div>
                    <div className='seasonalText'>Due to recent unforseen events, we have had to implement some new temporary store hours.<br></br>
                                                  We will be open from 10 a.m. till 5 p.m. Wednesday through Saturday. <br></br>
                                                  This means Otherwides will be closed on Sunday, Monday, and Tuesday. <br></br>
                                                  We are sorry for any inconvenience this causes, 
                                                  and will resume normal days/hours as soon as possible. <br></br>
                                                  Thanks for being an Otherwides customer and hope to see you soon!<br></br>
                    </div>
                </div>
            </div>
            <div className='firstSection'>
                <AnimateOnScroll 
                    children={<img className='shopImage' src= {storeImages[1].image} alt='store display' onClick={() => openModal(storeImages[1])}/>}
                    reappear={true} 
                    threshold={.3}
                    right={false}
                />
                <AnimateOnScroll
                    children={<div className='descriptionContainer'>
                                <h2>Welcome to Otherwides Shoes</h2>
                                <h3>The widest selection of shoes in the Valley</h3>
                                <div className='description'>
                                We are a local women-owned small business in Tempe, AZ. For the past 20 years we have provided our customers with a wide range of quality footwear
                                that fits. From casual sneakers to formal dress shoes, we have a huge variety of styles to choose from. We want to be <span className='line'>your</span> wide shoe store
                                </div>
                            </div>}
                    reappear={true}
                    threshold={.1}
                    right={true}
                />
            </div>
            <div className='homepageLinkContainer'>
            <Link
                className='homepageLink'
                to='/womens'>For Women
            </Link>
            <Link
                className='homepageLink'
                to='/newArrivals'>New Arrivals
            </Link>
            <Link
                className='homepageLink'
                to='/mens'>For<br></br> Men
            </Link>
            </div>
            <div className='secondSection'>
                <AnimateOnScroll
                    children={
                        <div className='services'>
                        <h3>Sizes:</h3>
                        <p>Women: 6 - 13 W, WW, XXW</p>
                        <p>Men: 8 - 18 3E - 6E</p>
                        <h3>Services:</h3>
                            <ul>
                            <li>Experienced staff capable of foot measurement</li>
                            <li>Phone orders accepted</li>
                            <li>Seasonal as well as core styles</li>
                            <li>Year-round clearance room</li>
                        </ul>
                        </div>}
                    reappear={true}
                    threshold={.2}
                    right={false}
                />
                <AnimateOnScroll
                    children={<img className='shopImage' src= {storeImages[0].image} alt='second store display' onClick={() => openModal(storeImages[0])} />}
                    reappear={true}
                    threshold={.3}
                    right={true}
                />
            </div>
            <div className='thirdSection'>
            <AnimateOnScroll 
                children={<AboutUs />}
                reappear={true}
                threshold={.3}
                right={true}
            />
            </div>
            <Carousel></Carousel>
            <AnimateOnScroll 
                children={<SignUpForm />}
                reappear={true}
                threshold={.3}
                right={true}
            />
            <AnimateOnScroll 
                children={<img className='shopImage' src= {storeImages[2].image} alt='store display' onClick={() => openModal(storeImages[2])} />}
                reappear={true} 
                threshold={.3}
                right={false}
            />
            <AnimateOnScroll 
                children={<MapMarker />}
                reappear={true}
                threshold={.3}
                right={true}
            />

            <div className={modalOpen ? 'modalOpen' : 'modalClosed'}>
                <span className='closeIcon' onClick={closeModal}>&times;</span>
                <div className='modalContent'>
                    <img className='modalPic' src= {activeImage.image} alt={activeImage.name}></img>
                </div>
            </div>
        
        </div>
    )
}